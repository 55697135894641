import React, { useState, useEffect } from 'react'
import { navigate, Link } from 'gatsby'
import { withApollo } from 'react-apollo'

import SEO from '../../components/global/SEO'
import Layout from '../../components/Layout'
import { Section } from '../../components/global/Section'
import { H2 } from '../../components/global/Headings'
import Loader from '../../components/global/Loader'

import { formatDate } from '../../utils/helpers'
import OrderBooking from '../../components/order/OrderBooking';
import { GET_ORDER_ID } from '../../components/order/queries';
import PaymentDetails from '../../components/order/PaymentDetails';
import { SET_STATUS, GET_STATUS } from '../../components/global/queries';

const Booking = ({ location, client }) => {
  const [orderId, setOrderId] = useState(null)

  useEffect(() => {
    const id = location.pathname.split('/')[2]
    if (!id) {
      navigate('/404')
    }
    setOrderId(id)
  }, [location.pathname]);

  const [order, setOrder] = useState(null);

  useEffect(() => {
    if (orderId) {
      fetchOrder()
    }
  }, [orderId]);

  const fetchOrder = async () => {
    const { data, errors } = await client.query({
      query: GET_ORDER_ID,
      variables: {
        id: orderId
      }
    })
    if (!errors) {
      if (!data.order || errors) {
        navigate('/404')
      }
      setOrder(data.order)
    } else {
      client.mutate({
        mutation: SET_STATUS,
        variables: {
          __typename: 'Status',
          code: 'ERROR',
          message: errors[0].message
        },
        refetchQueries: [{ query: GET_STATUS }]
      })
    }
  }
  
  if (order) {
    return (
      <Layout>
        <SEO 
          title="Order Confirmation • Coach Tours, Giants Causeway, Belfast • Tours Ireland"
        />
        <Section>
          <H2 icon>Order Confirmation</H2>
          <div className="mx-auto w-100 md:w-4/5 lg:w-3/5">
            <p className="text-gray-500 text-lg leading-relaxed mb-8 text-center">{`Thanks! Your order with `}<span className="text-green-500 font-bold">Tours Ireland</span>{` is now complete. We've sent your confirmation email to `}<span className="font-bold">{order.email}</span>{`.`}</p>
            <div className="flex flex-wrap">
              <div className="w-full">
                {order.bookings.map((booking) => (
                  <OrderBooking booking={booking} key={booking.id} />
                ))}
              </div>
              <div className="w-full xl:w-1/2">
                <div className="xl:mr-3 mb-6 xl:mb-0 shadow p-6 h-full">
                  <h2 className="mb-6 text-lg">Order Details</h2>
                  <div className="flex flex-wrap">
                    <h3 className="w-1/3 text-black text-sm normal-case font-bold">Order Date</h3>
                    <p className="w-2/3 text-sm text-gray-400 mb-2">{formatDate(order.createdAt, true)}</p>
                    <h4 className="w-1/3 text-black text-sm normal-case font-bold">Order ID</h4>
                    <p className="w-2/3 text-sm text-gray-400 mb-2">{order.id}</p>
                    <h3 className="w-1/3 text-black text-sm normal-case font-bold">Name</h3>
                    <p className="w-2/3 text-sm text-gray-400 mb-2">{order.name}</p>
                    <h3 className="w-1/3 text-black text-sm normal-case font-bold">Email</h3>
                    <p className="w-2/3 text-sm text-gray-400 mb-2">{order.email}</p>
                    <h3 className="w-1/3 text-black text-sm normal-case font-bold">Phone</h3>
                    <p className="w-2/3 text-sm text-gray-400">{order.phone}</p>
                  </div>
                </div>
              </div>
              <PaymentDetails details={order.stripe} />
            </div>
            <div className="flex w-full mt-12">
              <Link to="/" className="no-underline text-green-500 font-bold w-full lg:w-1/2">← Return Home</Link>
            </div>
          </div>
        </Section>
      </Layout>
    )
  }
  
  return (
    <Layout>
      <SEO 
        title="Order Confirmation • Coach Tours, Giants Causeway, Belfast • Tours Ireland"
      />
      <Section>
        <Loader />
      </Section>
    </Layout>
  )
  
}

export default withApollo(Booking)