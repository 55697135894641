import gql from 'graphql-tag'

const GET_ORDER_ID = gql`
  query GetOrderById($id: ID!) {
    order(where: {
      id: $id
    }) {
      id
      email
      name
      phone
      bookings {
        id
        journey {
          date
          trip {
            title
            image
          }
        }
        tickets {
          title
          cost
          quantity
        }
      }
      stripe {
        id
        amount
        charges {
          total_count
          data {
            id
            amount
            amount_refunded
            payment_method_details {
              type
              card {
                brand
                description
                last4
              }
            }
            receipt_url
            refunded
            refunds {
              data {
                amount
                reason
                status
              }
              total_count
            }
            status
          }
        }
        created
        status
      }
      createdAt
    }
  }
`

export {
  GET_ORDER_ID
}