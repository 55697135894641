import React, { Fragment } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCcVisa, faCcMastercard } from '@fortawesome/free-brands-svg-icons'
import { Alt2Badge, SuccessBadge, WarnBadge, ErrorBadge } from '../global/Badge';

const StatusBadge = ({charge}) => {
  if (charge.status === 'succeeded') return <SuccessBadge>Paid</SuccessBadge>
  if (charge.status === 'pending') return <WarnBadge>{charge.status}</WarnBadge>
  if (charge.status === 'failed') return <ErrorBadge>{charge.status}</ErrorBadge>
}

const PaymentDetails = ({ details }) => (
  <div className="w-full xl:w-1/2">
    <div className="xl:ml-3 mb-6 xl:mb-0 shadow p-6 h-full">
      <h2 className="mb-6 text-lg">Payment Details</h2>
      {details.charges.data.map((charge, index) => (
        <Fragment key={charge.id}>
          {charge.payment_method_details.type === 'card' &&
            <div className="flex flex-wrap mt-4 items-center">
              <div className="w-1/2">
                <span className="text-gray-900 font-bold">£{(charge.amount / 100).toFixed(2)}</span>
              </div>
              <div className="w-1/2 text-right">
                <StatusBadge charge={charge} />
              </div>
              <div className="w-full flex items-center mt-2">
                {charge.payment_method_details.card.brand === 'visa' && <FontAwesomeIcon className="visa text-2xl" icon={faCcVisa} /> }
                {charge.payment_method_details.card.brand === 'mastercard' && <FontAwesomeIcon className="mastercard text-2xl" icon={faCcMastercard} /> }
                <div className="ml-2 text-xs text-gray-400">
                  <span className="mr-2">{charge.payment_method_details.card.description}</span>
                  <span>•••• {charge.payment_method_details.card.last4}</span>
                </div>
              </div>
            </div>
          }
          {(index + 1) !== details.charges.data.length && <hr className="border-b border-gray-200 mt-4" />}
        </Fragment>
      ))}
    </div>
  </div>
)

export default PaymentDetails