import React from 'react'
import { formatDate } from '../../utils/helpers';
import Button from '../global/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTicketAlt, faTimes } from '@fortawesome/pro-regular-svg-icons';

const OrderBooking = ({ booking }) => (
  <div className="flex bg-white rounded-sm p-6 shadow w-full relative mb-6 text-left items-center">
    <img className="h-12 w-12 xs:h-16 xs:w-16 md:h-24 md:w-24 rounded-sm mr-4" src={`${booking.journey.trip.image}?w=200`} />
    <div className="flex-auto flex items-center">
      <div className="flex-auto mr-4">
        <h2 className="text-base md:text-lg mb-1">{booking.journey.trip.title}</h2>
        <div className="text-sm md:text-base text-gray-500">{formatDate(booking.journey.date)}</div>
        <div className="mt-2">
          {booking.tickets.map((ticket, index) => (
            <div className="flex items-center justify-between" key={`bk-${booking.id}-t-${index}`}>
              <p className="block sm:inline text-gray-300 text-xs md:text-sm">{ticket.quantity}x {ticket.title} ({`£${ticket.cost.toFixed(2)}`})</p>
            </div>
          ))}
        </div>
      </div>
      {/* <div className="sm:text-center flex-none mt-0">
        <Button className="btn bg-gray-700 text-center hover:bg-gray-600 text-xs flex justify-center items-center mb-2 px-3 py-2 tracking-normal md:px-4 md:py-3 md:tracking-wide"><FontAwesomeIcon icon={faTicketAlt} size={'lg'} className="mr-2" /> Get Tickets</Button>
        <button className="text-red-500 font-bold text-xs"><FontAwesomeIcon icon={faTimes} size={'lg'} className="mr-1" /> Cancel Booking</button>
      </div> */}
    </div>
  </div>
)

export default OrderBooking